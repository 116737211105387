import React from 'react';
import './stickyHelper.css'
import Menu from '../menu/Menu'

const StickyHeader = () => {

  return (
    <div className="sticky">
    <Menu />
    </div>
  );
};

export default StickyHeader